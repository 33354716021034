<template>
  <div class="container">
    <slot />
  </div>
</template>

<script>
export default {
  props: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_mixin.scss";

</style>
