/* eslint-disable */
import EventEmitter from 'events';

const animationLoopDefaults = {
  precision: 0.1,
  tension: 170,
  friction: 26,
  mass: 1,
  clamp: false,
  initialVelocity: 0,
  decayThreshold: 0.1,
  easing: (t) => t,
  interpolation: (t) => t,
};

export default class AnimationLoop extends EventEmitter {
  constructor(opts) {
    super();
    this.active = false;
    this.animations = {};
    this.props = {};
    this.promises = [];

    this._update(opts);
  }

  _update(opts) {
    if (!opts) return;
    const lastConfig = this.opts?.config;
    
    this.opts = opts;

    this.opts.config = { ...lastConfig, ...this.opts.config };

    for (const [prop, value] of Object.entries(this.opts)) {
      if (prop === 'from') continue;
      if (prop === 'config') continue;
      this._initAnimation(prop, value);
    }

    for (const prop in this.opts.from) {
      this._initAnimation(prop, this.opts[prop]);
    }

    this.props = {};
    for (const [prop, animation] of Object.entries(this.animations)) {
      this.props[prop] = animation.from;
    }
  }

  _initAnimation(prop, value) {
    const { opts } = this;

    const to = typeof value !== 'number' ? opts.from[prop] || 0 : value;

    const from = opts.from ? opts.from[prop] : this.props[prop];

    this.animations[prop] = {
      ...this.animations[prop],
      lastPosition: from,
      config: { ...animationLoopDefaults, ...opts.config },
      from,
      to,
      done: false,
    };
  }

  _prepareAnimationsForUpdate() {
    this.animations = Object.entries(this.animations).reduce(
      (acc, [key, anim]) => {
        acc[key] = {
          ...anim,
          active: true,
          done: false,
          startTime: new Date().getTime(),
          lastTime: anim.active ? anim.lastTime : new Date(),
        };
        return acc;
      },
      {},
    );
  }

  start() {
    this.active = true;
    this._prepareAnimationsForUpdate();
    this.loop();

    const animationLoop = () => {
      window.requestAnimationFrame(() => {
        const next = this.loop.call(this);
        if (next) animationLoop();
        else {
          this.active = false;
          this.resolvePromises();
        }
      });
    };

    animationLoop();
    return this.newPromise();
  }
  
  newPromise() {
    let resolver, rejecter;
    const promise = new Promise((resolve, reject) => { 
      resolver = resolve;
      rejecter = reject;
    });
    this.promises.push({ resolve: resolver, reject: rejecter });
    return promise;
  }

  resolvePromises() {
    this.promises.forEach(({ resolve }) => resolve());
    this.promises = [];
  }

  rejectPromises() {
    this.promises.forEach(({ reject }) => reject());
    this.promises = [];
  }

  next(opts) {
    this.rejectPromises();
    this._update(opts);
    if (!this.active) {
      return this.start();
    }
    this._prepareAnimationsForUpdate();
    return this.newPromise();
  }

  stop() {
    this.active = false;
    this.animations = {};
    this.resolvePromises();
  }

  _publishStep() {
    this.emit('change', this.props);
  }

  _publishPropertyChange(prop, value) {
    this.emit('propertyChange', { name: prop, value });
  }

  loop() {
    if (!this.active) return false;

    let done = true;
    for (const [prop, animation] of Object.entries(this.animations)) {
      const [ongoing, value] = this._animateProperty(animation);
      this.props[prop] = value;
      this._publishPropertyChange(prop, value);
      if (ongoing) done = false;
    }

    this._publishStep();

    return !done;
  }

  _animateProperty(animation) {
    const time = new Date();

    const { config } = animation;

    // If an animation is done, skip, until all of them conclude
    if (animation.done) return [false, animation.lastPosition];

    const { from } = animation;
    let { to } = animation;
    let position = animation.lastPosition;
    let velocity = config.initialVelocity;

    // Conclude animation if it's either immediate, or from-values match end-state
    if (config.immediate) {
      animation.done = true;
      return [false, to];
    }

    // Break animation when string values are involved
    if (typeof from === 'string' || typeof to === 'string') {
      animation.done = true;
      return [false, to];
    }

    let endOfAnimation = false;

    if (config.duration !== void 0) {
      /** Duration easing */
      position = from
        + config.easing((time - animation.startTime) / config.duration)
          * (to - from);
      endOfAnimation = time >= animation.startTime + config.duration;
    } else if (config.decay) {
      const gamma = config.decay; // 1 - 0.998;
      const dt = time - animation.startTime;
      /** Decay easing */
      position = from + (velocity / gamma) * (1 - Math.exp(-gamma * dt));

      endOfAnimation = Math.abs(animation.lastPosition - position) < config.decayThreshold;
      if (endOfAnimation) to = position;
    } else {
      /** Spring easing */
      let lastTime = animation.lastTime !== void 0 ? animation.lastTime : time;
      velocity = animation.lastVelocity !== void 0
        ? animation.lastVelocity
        : config.initialVelocity;

      // If we lost a lot of frames just jump to the end.
      if (time > lastTime + 64) lastTime = time;
      // http://gafferongames.com/game-physics/fix-your-timestep/
      const numSteps = Math.floor(time - lastTime);
      for (let i = 0; i < numSteps; ++i) {
        const force = -config.tension * (position - to);
        const damping = -config.friction * velocity;
        const acceleration = (force + damping) / config.mass;
        velocity += (acceleration * 1) / 1000;
        position += (velocity * 1) / 1000;
      }
      
      // Conditions for stopping the spring animation
      const isOvershooting = config.clamp && config.tension !== 0
        ? from < to
          ? position > to
          : position < to
        : false;
      const isVelocity = Math.abs(velocity) <= config.precision;
      const isDisplacement = config.tension !== 0
        ? Math.abs(to - position) <= config.precision
        : true;

      endOfAnimation = isOvershooting || (isVelocity && isDisplacement);
      animation.lastVelocity = velocity;
      animation.lastTime = time;
    }

    if (endOfAnimation) {
      // Ensure that we end up with a round value
      if (animation.value !== to) position = to;
      animation.done = true;
      animation.active = false;
    } else animation.active = true;

    animation.lastPosition = position;

    return [!endOfAnimation, position];
  }
}
