import { createApp } from 'vue';
import { createHead } from '@vueuse/head';
import VueScrollTo from 'vue-scrollto';
import VueGtag from 'vue-gtag';
import smoothscroll from 'smoothscroll-polyfill';

import App from '@/App.vue';
import Go from '@/components/utils/Go.vue';
import Arrow from '@/components/utils/Arrow.vue';
import Image from '@/components/utils/Image.vue';
import Dropdown from '@/components/utils/Dropdown.vue';
import Accordion from '@/components/utils/Accordion.vue';
import Clip from '@/components/utils/Clip.vue';
import Slider from '@/components/utils/Slider.vue';
import Card from '@/components/utils/Card.vue';
import BlogCard from '@/components/utils/BlogCard.vue';
import DogCard from '@/components/utils/DogCard.vue';
import Spotlight from '@/components/utils/Spotlight.vue';

import store from './store';
import checkView from './vue-check-view';
import { cleanSvgDirective } from './utils/svg';
import registerTextSplit from './utils/textSplit';

import './assets/styles/main.scss';

// polyfills
// --------------------------------

smoothscroll.polyfill();

// app
// --------------------------------
const app = createApp(App);

const head = createHead();
app.use(head);

app.use(store);
app.use(VueScrollTo);
app.use(VueGtag, {
  config: {
    id: 'G-K50PRCL664',
  },
  enabled: !window.prerenderer,
});

if (!window.prerender) {
  app.use(checkView, { insideThreshold: () => (window.innerWidth < 960 ? 0.1 : 0.15) });
} else {
  // Stub v-view and v-scroll-trigger
  app.directive('view', {});
  app.directive('scroll-trigger', {});
}

app.directive('no-anim', {
  beforeMount() {
    if (!window.prerender) window.document.body.classList.remove('no-anim');
  },
});

app.directive('clean-svgs', cleanSvgDirective);

registerTextSplit(app);

window.getPath = () => window.location.pathname.replace(/\/$/, '');
window[atob('amt3ZWI=')] = atob('Tm8sIHRoaXMgaXMgUGF0cmljayE');
app.config.globalProperties.$tryCDN = (url, width) => {
  if (url && url.includes('backend.jkweb.dev')) {
    const el = document.createElement('a');
    el.href = url;
    return `https://jkweb-dev.b-cdn.net${el.pathname}${width ? `?width=${width}` : ''}`;
  }
  return url;
};

// i18n
// --------------------------------

// custom components
// --------------------------------
app.component('Go', Go);
app.component('Image', Image);
app.component('Arrow', Arrow);
app.component('Accordion', Accordion);
app.component('Dropdown', Dropdown);
app.component('Clip', Clip);
app.component('Slider', Slider);
app.component('Card', Card);
app.component('BlogCard', BlogCard);
app.component('DogCard', DogCard);
app.component('Spotlight', Spotlight);

app.mount('#app');
