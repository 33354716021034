<template>
  <div :class="{ showNavbar, onTop }">
    <slot />
  </div>
</template>

<script>
export default {
  data: () => ({
    showNavbar: true,
    onTop: true,
    lastScrollPosition: 0,
  }),
  mounted() {
    window.addEventListener('scroll', this.onScroll, { passive: true });

    const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    if (currentScrollPosition > 100) {
      this.showNavbar = false;
    }
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    onScroll() {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;

      this.onTop = currentScrollPosition < window.innerHeight * 0.8;

      if (currentScrollPosition < 0) {
        return;
      }

      if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) {
        return;
      }

      this.showNavbar = currentScrollPosition < this.lastScrollPosition;
      this.lastScrollPosition = currentScrollPosition;
    },
  },
};
</script>
