import { ref, watch } from 'vue';

// eslint-disable-next-line import/prefer-default-export
export function useStorageRef(name, initialValue) {
  const storageValue = localStorage.getItem(name);
  const state = ref(storageValue ? JSON.parse(storageValue) : initialValue);

  watch(state, (newState) => {
    localStorage.setItem(name, JSON.stringify(newState));
  });

  return state;
}
