<template>
  <div
    class="spotlight"
    :class="{
      active: isActive,
      stable,
    }"
  >
    <div
      ref="sizerRef"
      class="sizer"
    >
      <slot
        :active="false"
        :activate="() => isActive = true"
        :close="() => isActive = false"
      />
    </div>
    <teleport to="body">
      <div
        :class="{ active: isActive }"
        class="backdrop"
      />
    </teleport>
    <div
      v-if="!isActive"
      ref="contentRef"
      class="content"
      :class="{ active: isActive, stable }"
    >
      <slot
        :active="delayedActive"
        :activate="() => isActive = true"
        :close="() => isActive = false"
      />
    </div>
    <teleport to="body">
      <div
        v-if="isActive"
        ref="contentRef"
        class="content"
        :class="{ active: isActive, stable }"
      >
        <slot
          :active="delayedActive"
          :activate="() => isActive = true"
          :close="() => isActive = false"
        />
      </div>
    </teleport>
  </div>
</template>

<script>
import {
  reactive, ref, toRefs, watch, onMounted, onUnmounted, nextTick,
} from 'vue';
import { cssVars } from '@/utils/css';
import { theme } from '@/utils/tailwind';
import AnimationLoop from '@/utils/spring';
import { useOptionalModel } from '@/composables/model';
import { useResizeObserver, useWindowSize } from '@/composables/observer';
import useScrollLock from '@/composables/scrolllock';

function updateRect(sizerRef, rect) {
  if (!sizerRef.value) return;
  const update = sizerRef.value?.getBoundingClientRect();
  rect.left = update?.left;
  rect.top = update?.top;
  rect.width = update?.width;
  rect.height = update?.height;
}

function useScroll(cb) {
  onMounted(() => {
    cb();
    window.addEventListener('scroll', cb);
  });

  onUnmounted(() => {
    window.removeEventListener('scroll', cb);
  });
}

function track(sizerRef, rect) {
  const { observe, unobserve } = useResizeObserver(sizerRef, () => {
    updateRect(sizerRef, rect);
  }, { box: 'border-box' });

  useWindowSize(updateRect);
  useScroll(() => updateRect(sizerRef, rect));
  onMounted(observe);
  onUnmounted(unobserve);

  return () => updateRect(sizerRef, rect);
}

function withSpring(animate, opts) {
  const spring = new AnimationLoop(opts);

  spring.on('change', animate);

  return (props) => spring.next(props);
}

function useScreenDimensions() {
  const getDim = () => ({
    width: document.body.offsetWidth,
    height: window.innerHeight,
    left: document.body.offsetWidth / 2,
    top: window.innerHeight / 2,
  });

  const dim = reactive(getDim());

  useWindowSize(() => {
    const newDim = getDim();
    dim.width = newDim.width;
    dim.height = newDim.height;
    dim.left = newDim.left;
    dim.top = newDim.top;
  });

  return dim;
}

export default {
  props: {
    active: { type: Boolean },
    activeWidth: { type: Number, default: 0.3 },
    activeHeight: { type: Number, default: 0.8 },
  },
  setup(props, { emit }) {
    const sizerRef = ref(null);
    const contentRef = ref(null);
    const stable = ref(true);
    const origin = reactive({});
    const isActive = useOptionalModel(toRefs(props), emit, 'active');
    const screenDimensions = useScreenDimensions();
    const isAnimatingIn = ref(false);
    const isAnimatingOut = ref(false);
    const maxWidth = (v) => (v * props.activeWidth > parseInt(theme().screens.xl, 10)
      ? parseInt(theme().screens.xl, 10)
      : v * props.activeWidth);
    const maxHeight = (v) => v * props.activeHeight;
    const { lock, unlock } = useScrollLock();

    const setBounds = (target) => {
      if (!contentRef.value) return;
      contentRef.value.style.width = `${target.width}px`;
      contentRef.value.style.height = `${target.height}px`;
      contentRef.value.style.left = `${target.left}px`;
      contentRef.value.style.top = `${target.top}px`;
    };

    const resetBounds = () => {
      if (!contentRef.value) return;
      contentRef.value.style.removeProperty('width');
      contentRef.value.style.removeProperty('height');
      contentRef.value.style.removeProperty('left');
      contentRef.value.style.removeProperty('top');
    };

    const animateContentTo = withSpring((values) => {
      if (!contentRef.value) return;
      contentRef.value.style.width = `${values.width}px`;
      contentRef.value.style.height = `${values.height}px`;
      contentRef.value.style.left = `${values.left}px`;
      contentRef.value.style.top = `${values.top}px`;
    }, { config: { precision: 5 } });

    const doTrack = track(sizerRef, origin);

    watch([isActive, screenDimensions], async ([newActive, newDim], [prevActive]) => {
      const newOrigin = {
        ...origin,
        left: origin.left + origin.width / 2,
        top: origin.top + origin.height / 2,
      };
      const target = { ...newDim };
      if (newActive === prevActive) return;

      if (newActive) {
        lock();
        stable.value = false;
        isAnimatingIn.value = true;
        setBounds(newOrigin);
        try {
          await animateContentTo({
            from: newOrigin,
            ...target,
            width: maxWidth(newDim.width),
            height: maxHeight(newDim.height),
          });
          resetBounds();
        // eslint-disable-next-line no-empty
        } catch (e) {
        } finally {
          isAnimatingIn.value = false;
        }
      } else if (prevActive) {
        unlock();
        isAnimatingOut.value = true;
        const { width, height } = contentRef.value?.getBoundingClientRect();
        const { left, top } = {
          left: target.left - origin.left,
          top: target.top - origin.top,
        };
        const from = {
          left, top, width, height,
        };
        await nextTick();
        setBounds(from);

        try {
          await animateContentTo({
            from,
            ...newOrigin,
            left: origin.width / 2,
            top: origin.height / 2,
          });
          // resetBounds();
          stable.value = true;
          resetBounds();
        // eslint-disable-next-line no-empty
        } catch (e) {
        } finally {
          isAnimatingOut.value = false;
        }
      }
    });

    watch(origin, (newOrigin) => {
      if (isAnimatingIn.value) {
        animateContentTo({
          ...newOrigin,
          left: screenDimensions.left,
          top: screenDimensions.top,
          width: maxWidth(screenDimensions.width),
          height: maxHeight(screenDimensions.height),
        });
      }
    });

    const delayedActive = ref(isActive.value);

    watch(isActive, async (newActive) => {
      await nextTick();
      delayedActive.value = newActive;
    });

    return {
      sizerRef,
      contentRef,
      isActive,
      delayedActive,
      stable,
      cssVars,
      doTrack,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_mixin.scss';
@import '@/assets/styles/_global.scss';

.spotlight {
  position: relative;

  .sizer {
    visibility: hidden;
  }

  &.active {
    z-index: 1000;
  }
}

.backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--color-dark-gray);
  opacity: 0;
  pointer-events: none;
  will-change: opacity;
  transition: opacity var(--speed-fast);
  z-index: 100;
}

.content.stable {
  z-index: unset;
}

.backdrop.active {
  opacity: 0.3;
  pointer-events: all;
}

.content.active {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: theme("screens.xl");
}

.sizer {
  & > :deep(*) {
    width: 100%;
    height: 100%;
  }
}
.content.stable {
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  will-change: left, top, width, height;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
</style>
