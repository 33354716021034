import Vue from 'vue';

function update(el, binding) {
  const brReg = /<\s*(?:br|BR|\n)\s*\/?>/g;
  const parts = binding.value.split(brReg);
  const spans = parts.map((part) => `<span class="text-part">${part.replace(/(<([^>]+)>)/gi, '')}</span>`);
  el.innerHTML = `<span class="text-parts">${spans.join('')}</span>`;
}

export default function register(app) {
  app.directive('text-split', {
    beforeMount(el, binding, vnode) {
      update(el, binding);
    },
    updated(el, binding, vnode) {
      update(el, binding);
    },
  });
}
