<template>
  <component :is="as" class="clip-top" :style="cssVars({ clipTop, clipTopMobile })">
    <div class="clip-bottom w-full" :style="cssVars({ clipBottom, clipBottomMobile })">
      <slot />
    </div>
  </component>
</template>

<script>
import { cssVars } from '@/utils/css';

export default {
  props: {
    as: { type: String, default: 'section' },
    clipTop: { type: String },
    clipTopMobile: { type: String },
    clipBottom: { type: String },
    clipBottomMobile: { type: String },
  },
  setup() {
    return { cssVars };
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_mixin.scss';

.clip-top {
  @include responsive('clip-path', var(--clip-top-mobile, var(--clip-top)), var(--clip-top));
}
.clip-bottom {
  @include responsive('clip-path', var(--clip-bottom-mobile, var(--clip-bottom)), var(--clip-bottom));
}
</style>
