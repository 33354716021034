<template>
  <span v-if="!to" @click="emit('click', $event)">
    <slot />
  </span>

  <a
    v-else-if="isAnchor(to)"
    v-scroll-to="isAnchor(to)"
    :disabled="disabled"
    :href="href"
    class="cursor-pointer"
    @click="$emit('click', $event)"
  >
    <slot />
  </a>

  <a
    v-else-if="to.startsWith('http')"
    :disabled="disabled"
    :href="href"
    rel="nofollow noopener"
    target="_blank"
    @click="$emit('click', $event)"
  >
    <slot />
  </a>

  <a
    v-else-if="to.startsWith('tel:') || to.startsWith('mailto:')"
    :disabled="disabled"
    href="href"
    @click.prevent="special(to)"
  >
    <slot />
  </a>

  <a
    v-else
    :href="href"
    :disabled="disabled"
    @click="internal($event)"
  >
    <slot />
  </a>
</template>

<script>
export default {
  props: {
    to: { type: String, default: null },
    disabled: { type: Boolean, default: false },
  },
  emits: ['click'],
  computed: {
    href() {
      if (this.disabled) return '#';
      if (this.isAnchor(this.to)) {
        return this.isAnchor(this.to);
      }
      if (this.to.startsWith('http')) {
        return this.to;
      }
      if (this.to.startsWith('tel:') || this.to.startsWith('mailto:')) {
        return '#';
      }
      return this.to;
    },
  },
  methods: {
    isAnchor(to) {
      if (to.startsWith('#')) return to;
      return false;
    },
    hasAnchor(to) {
      const match = to.match(/#[^#]+$/);
      if (match) return match[0];
      return false;
    },
    special(to) {
      this.$emit('click');
      window.location.href = to.replace(/ /g, '');
    },
    internal(e) {
      if (this.disabled) return;
      this.$emit('click', e);
      if (e.defaultPrevented) return;
      e.preventDefault();
      this.$store.dispatch('router/push', {
        uri: this.to,
        scrollTo: this.hasAnchor(this.to),
      });
    },
  },
};
</script>
