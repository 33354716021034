<template>
  <footer class="footer">
    <div class="constrain">
      <div class="footer-main">
        <div class="row-12">
          <div class="col-12 md:col-6 order-4 md:order-1">
            <logo class="logo" />
          </div>

          <div class="col-12 md:col-2 order-1 md:order-2 links">
            <div class="md:ml-auto">
              <span class="heading-line" />
              <span class="heading">{{ payload.contact }}</span>
              <div class="meta-links text-xs">
                <a :href="`mailto:${payload.email}`" class="link">
                  hello@ginevra&#8203;cobberdog.ch
                </a>
              </div>
            </div>
          </div>

          <div class="col-12 md:col-2 order-2 md:order-3 links">
            <div class="md:ml-auto">
              <span class="heading-line" />
              <span class="heading">{{ payload.social }}</span>
              <div class="meta-links text-xs">
                <Go :to="payload.facebook" class="link">
                  Facebook
                </Go>
                <Go :to="payload.instagram" class="link">
                  Instagram
                </Go>
              </div>
            </div>
          </div>

          <div class="col-12 md:col-2 order-3 md:order-4 links">
            <div class="md:ml-auto">
              <span class="heading-line" />
              <span class="heading">{{ payload.other }}</span>
              <div class="meta-links text-xs">
                <Go :to="payload.qa?.link" class="link">
                  {{ payload.qa?.title }}
                </Go>
                <Go :to="payload.impressum?.link" class="link">
                  {{ payload.impressum?.title }}
                </Go>
                <Go :to="payload.datenschutz?.link" class="link">
                  {{ payload.datenschutz?.title }}
                </Go>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import Logo from '@/assets/images/footer_logo.svg';
import Container from '../utils/Container.vue';

export default {
  components: {
    Logo,
    Container,
  },
  computed: {
    payload() {
      return this.$store.state.router.layout.footer || {};
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/styles/_mixin.scss';

.footer {
  @apply py-8;
  position: relative;
  z-index: 10;
  background-color: var(--color-primary);

  .heading {
    color: var(--color-very-dark-gray);
    @apply block mb-2;
    font-size: px(26);
    line-height: px(32);
    font-weight: 800;
  }
}
.footer-main {
}

.credit {
  color: #b29b3a;
}

.links {
  display: flex;
  flex-flow: column;

  margin-bottom: px(30);

  @screen md {
    &.other {
      display: none;
    }
    margin-bottom: 0;
  }

  .link {
    @apply mb-2;
    @apply text-sm;

    padding: 0;
    display: block;
    line-height: px(15);
    color: var(--color-dark-gray);
    font-size: px(16);

    @screen md {
      line-height: px(22);
    }
  }
}

.credit-link:not(:last-child)::after {
  content: ', ';
  color: #b29b3a;
}

.heading-line {
  background: #88bbe3;
  height: px(3);
  width: px(18);
  display: block;
  margin-bottom: px(8);
}

.logo {
  @include responsive('margin-top', px(24), 0);
}
</style>
