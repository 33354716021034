<template>
 <HideOnScroll>
  <header v-clean-svgs class="header">
    <div class="constrain flex items-center h-full">
      <div class="logo">
        <Go :to="'/' + $store.state.router.locale" aria-label="Home">
          <Logo />
        </Go>
      </div>

      <div id="nav" class="ml-auto">
        <div class="relative flex items-center justify-end">
          <nav
            ref="navRef"
            class="inner flex"
          >
            <Go
              v-for="link of navigation?.links"
              :id="`link-${link.id}`"
              :key="link.id"
              :to="link.uri"
              class="mx-4 link"
              :class="{ active: link.uri === currentUri }"
              @mouseenter="selectSubLink(link)"
              @mouseleave="subnavActive = false"
              v-text="link.title"
            />
          </nav>
        </div>
      </div>

      <div
        ref="subnavRef"
        class="subnav"
        :class="{
          active: !!subnavActive,
        }"
        :style="cssVars({ subnavOffset })"
      >
        <Go
          v-for="link of selectedLink?.links"
          :key="link.id"
          :to="link.uri"
          class="mx-4 link"
          :class="{ active: link.uri === currentUri }"
          v-text="link.title"
        />
      </div>

      <Hamburger
        class="md:hidden"
        :class="{ active: mobile }"
        @click="toggle()"
      />

      <div class="mobile-wrapper">
        <div class="mobile-nav" :class="{ active: mobile }">
          <div class="mobile-links">
            <Go
              v-for="sub of navigation?.links"
              :key="sub.id"
              :to="sub.uri"
              class="px-4 link"
              :class="{ active: sub.uri === currentUri }"
              @click="toggle(true)"
              v-text="sub.title"
            />
          </div>
        </div>
      </div>
    </div>
  </header>
 </HideOnScroll>
</template>

<script>
import { nextTick, ref } from 'vue';
import Logo from '@/assets/images/header_logo.svg';
import { cssVars } from '@/utils/css';
import Language from '../utils/Language.vue';
import Hamburger from '../utils/Hamburger.vue';
import Container from '../utils/Container.vue';
import HideOnScroll from '../utils/HideOnScroll.vue';

export default {
  components: {
    Logo,
    Language,
    Hamburger,
    Container,
    HideOnScroll,
  },
  setup() {
    const navRef = ref(null);
    const subnavRef = ref(null);
    const selectedLink = ref(null);
    const subnavActive = ref(false);
    const subnavOffset = ref(null);

    const selectSubLink = async (link) => {
      if (!link?.links?.length) return;
      const selector = `#link-${link?.id}`;
      selectedLink.value = link;

      await nextTick();

      const element = navRef.value?.querySelector(selector);
      const rect = element.getBoundingClientRect();
      const left = Math.min(
        rect?.left + rect?.width / 2,
        window.innerWidth - subnavRef.value?.getBoundingClientRect().width / 2,
      );
      subnavOffset.value = `${left}px`;
      subnavActive.value = true;
    };

    return {
      navRef,
      subnavRef,
      selectedLink,
      subnavActive,
      subnavOffset,
      cssVars,
      selectSubLink,
    };
  },
  data: () => ({
    mobile: false,
  }),
  computed: {
    currentUri() {
      return this.$store.state.router.page.meta?.uri;
    },
    navigation() {
      return this.$store.state.router.layout.navigation;
    },
  },
  methods: {
    toggle(close) {
      if (close === undefined) close = this.mobile;
      if (close) {
        this.mobile = false;
        setTimeout(() => this.unlock(), 400);
      } else {
        this.mobile = true;
        setTimeout(() => this.lock(), 400);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_mixin.scss';
@import '@/assets/styles/_grid.scss';
@import '@/assets/styles/_global.scss';

.logo {
  position: absolute;
  z-index: 2;
  align-self: flex-start;
  margin-top: px(22);
  height: 100%;
  @include responsive('height', 80%, 173%);
  @include responsive('width', 7rem, 'auto');
  a {
    position: relative;
    height: 100%;
    width: 100%;
    display: block;
    &:hover {
      opacity: 1;
    }
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

.header {
  top: 0;
  z-index: 100;
  width: 100vw;
  height: var(--header-height);
  @include responsive('background-color', transparent, var(--color-secondary));
  @apply fixed;
  transform: translateY(-200%);
  transition: .15s ease-in-out;
}

.showNavbar {
  .header {
    transform: translateY(0);
  }
}

#nav {
  & > :first-child {
    margin: auto;
  }

  color: var(--dark-grey);

  @media (max-width: theme('screens.md')) {
    .inner {
      position: absolute;
      top: 100%;
      left: 0;
      max-height: 0;
      width: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      background: white;
    }
  }
}

.hamburger {
  position: fixed;
  z-index: 1002;
  right: 0;
  top: calc(var(--header-height) / 2 - 13px);
  transform: translateY(calc(-50% + 4px));
  margin-right: px(27);
}

.inner,
.mobile-nav,
.hamburger {
  pointer-events: all;
}

nav .link {
  @apply uppercase;
  @apply text-m;
  @apply font-bold;
  line-height: px(30);
  color: var(--color-dark-gray);
  transition: color var(--speed-fast) ease;

  position: relative;
  margin-top: auto;
  margin-bottom: auto;

  &::after {
    @apply bg-orange-dark;

    content: '';
    position: absolute;
    left: 50%;
    top: calc(100% + #{px(4)});
    width: 0;
    height: 2px;
    transition: width var(--speed-fast) ease;
    transform: translateX(-50%);
  }

  &::before {
    content: "";
    position: absolute;
    top: 100%;
    height: px(35);
    width: 100%;
  }

  &.active::after {
    width: 8px;
  }

  &:hover {
    @apply text-dark-orange;

    &::after {
      width: 100%;
    }
  }
  #nav &.active:last-child::after {
    content: none;
  }
}

.subnav {
  @apply bg-white;
  width: fit-content;
  position: absolute;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  padding: px(10) px(25);
  top: 120%;
  left: var(--subnav-offset, 0);
  transform: translateX(-50%);
  transition:
    opacity var(--speed-fast) ease,
    top var(--speed-fast) ease,
    transform var(--speed-fast) ease;
  opacity: 0;
  pointer-events: none;

  &.active,
  &:hover {
    opacity: 1;
    top: 100%;
    pointer-events: all;
  }

  .link {
    @apply text-dark-gray;
    padding: px(10) 0;
    line-height: px(40);

    font-size: 1.2rem;

  }
}

/* MOBILE NAV */
.mobile-nav {
  @apply bg-blue;

  position: fixed;
  overflow-y: scroll;

  z-index: 1001;
  top: 0;
  right: 0;
  min-height: 100vh;
  width: 100%;
  transform: translateX(100%);
  transition: transform var(--speed-fast) ease-out;

  &, & a {
    @apply text-dark-gray;
  }

  &.active {
    transform: none;
    @screen md {
      transform: translateX(100%);
    }
  }

  .link {
    margin: px(16) 0;
    padding: 0;
    text-decoration: none !important;
  }

  .mobile-links {
    display: flex;
    flex-direction: column;
    text-align: right;
    margin-top: px(132);
    margin-right: var(--content-padding);

    .link {
      @apply font-bold;

      line-height: px(35);
      font-size: px(30);

      &.active {
        @apply text-primary;
      }

      &.active::after {
        content: none;
      }
    }
  }
}

.link-line .head {
  @apply flex;
  @apply justify-between;
  @apply items-center;
  @apply text-white;
}

:deep(.accordion.expanded) .head {
  color: rgba(255, 255, 255, 0.6);
}

.link-line .children {
  @apply flex;
  @apply flex-col;
  @apply items-start;
  padding-top: px(3);
  padding-bottom: px(12);

  .link {
    margin-top: px(8);
    margin-bottom: px(8);
    line-height: px(22);
  }
}
:deep(.dropdown):not(:last-child) {
  @apply border-b;
  border-bottom-color: rgba(255, 255, 255, 0.6);
}

:deep(.arrow) {
  .has-children & {
    &.down {
      transform: rotate(90deg);
    }
    &.active {
      transform: rotate(-90deg);
    }
  }
}
</style>
