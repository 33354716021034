import {
  computed, ref, watch,
} from 'vue';

// eslint-disable-next-line import/prefer-default-export
export function useModel(props, emit, name = 'modelValue') {
  return computed({
    get: () => props[name],
    set: (value) => emit(`update:${name}`, value),
  });
}

export function useOptionalModel(props, emit, name) {
  const internal = ref(props[name].value);

  watch(props[name], (newValue) => {
    internal.value = newValue;
  });

  return computed({
    get: () => internal.value,
    set: (value) => {
      internal.value = value;
      emit(`update:${name}`, value);
    },
  });
}
