<template>
  <div class="card">
    <div class="clip-wrapper">
      <clip clip-bottom="ellipse(90% 60% at 20% 30%)">
        <Image :src="image" />
      </clip>
      <slot name="top" />
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    image: { type: Object },
  },
};
</script>

<style lang="scss">
.card {
  @apply bg-white;

  clip-path: inset(0 round theme('borderRadius.medium'));

  display: flex;
  flex-flow: column;

  overflow: hidden;

  img {
    will-change: aspect-ratio;
    transition: aspect-ratio var(--speed-fast) ease;
    aspect-ratio: 3 / 2;
    object-fit: cover;
  }
}
.clip-wrapper {
  position: relative;
}
</style>
