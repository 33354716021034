<template>

  <div id="overlay" />

  <cookie-consent />

  <Navigation />

  <main v-no-anim>
    <Dynamic v-if="content" :inject="content" />
  </main>

</template>

<script>

import Navigation from '@/components/structure/Navigation.vue';
import Footer from '@/components/structure/Footer.vue';
import Dynamic from '@/components/structure/Dynamic.vue';
import dynamicPage from '@/composables/dynamicPage';
import CookieConsent from './components/structure/CookieConsent.vue';

export default {
  components: {

    Navigation,
    Footer,
    Dynamic,
    CookieConsent,

  },

  setup() {
    dynamicPage();
  },

  computed: {
    content() {
      return this.$store.state.router.page?.content;
    },
  },

};
</script>
