<template>
  <div class="backdrop" :class="{ active: needsConsent }" />
  <div class="cookie-consent-placeholder">
    <transition name="fade">
      <div v-if="needsConsent" class="cookie-consent">
        <bone class="bone" />
        <div class="title" v-html="texts?.cookies" />
        <div class="buttons">
          <button class="btn ok" @click="needsConsent = false">
            {{ texts?.ok }}
          </button>
          <Go class="btn agb" :to="agbLink" @click="needsConsent = false">
            {{ texts?.agbs }}
          </Go>
        </div>
        <div class="cookie-info" v-html="texts?.cookieInfo" />
      </div>
    </transition>
  </div>
</template>

<script>
import { computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useStorageRef } from '@/composables/localStorage';
import Bone from '@/assets/images/bone.svg?inline';
import scrolllock from '@/composables/scrolllock';

export default {
  components: {
    Bone,
  },
  setup() {
    const store = useStore();
    const { lock, unlock } = scrolllock();
    const needsConsent = useStorageRef('cookie-consent', true);
    onMounted(() => {
      if (needsConsent.value) {
        lock();
        watch(needsConsent, (consent) => {
          if (!consent) unlock();
        });
      }
    });
    return {
      needsConsent,
      agbLink: computed(() => store.state.router.layout?.agbLink),
      texts: computed(() => store.state.router.layout?.texts),
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_mixin.scss';

.cookie-consent {
  @apply rounded-medium;
  @apply bg-blue;

  display: flex;
  flex-flow: column;
  align-items: center;
  position: fixed;
  left: 50%;
  top: 50%;
  border: 10px solid theme('colors.white');
  transform: translate(-50%, -50%);
  z-index: 1000;
  text-align: center;

  @include responsive('padding', px(24) px(24), px(51) px(55));
  @include responsive('padding-top', px(32), px(81));
  @include responsive('width', 95vw, 50vw);
  max-width: px(800);
}

.title {
  font-size: px(38);
  line-height: px(46);
  font-weight: 800;

  @include responsive('margin-bottom', px(14), px(25));
}

.bone {
  width: 40%;
  max-width: px(250);

  @include responsive('margin-bottom', px(14), px(26));
}
.buttons {
  @include responsive('margin-bottom', px(18), px(39));
}
.cookie-info {
  @apply text-base;
  @apply font-bold;
  width: 80%;

  line-height: px(22);
}

.backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--color-dark-gray);
  z-index: 999;
  opacity: 0;
  will-change: opacity;
  transition: opacity var(--speed-fast) ease;
  pointer-events: none;

  &.active {
    opacity: 0.2;
    pointer-events: all;
  }
}

.btn.ok {
  @apply bg-dark-gray;
  margin-right: px(15);
  border: 2px solid transparent;

  &:hover {
    color: var(--color-dark-gray)!important;
    background: rgba(0, 0, 0, 0);
    border: 2px dashed var(--color-dark-gray);
  }
}
.btn.agb {
  @apply text-dark-gray;
  background: rgba(0, 0, 0, 0);
  border: 2px dashed var(--color-dark-gray);

  &:hover {
    @apply bg-dark-gray;
  }
}
</style>
