<template>
  <div class="hamburger">
    <div class="bar" />
    <div class="bar" />
    <div class="bar" />
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.hamburger {
  box-sizing: content-box;
  width: 35px;

  &.active .bar {
    @apply bg-dark-gray;
  }
}

.bar {
  @apply bg-blue-medium;

  width: 100%;
  height: 4px;
  transition: all 0.5s;
  border-radius: 2px;
  margin-left: auto;

  &:nth-child(1) {
    margin-bottom: 8px;
    .active & {
      transform: translateY(4px) rotate(45deg) scale(.7);
    }
  }

  &:nth-child(2) {
    transform: translateX(0);
    margin-bottom: 8px;
    .active & {
      opacity: 0;
      transform: translateX(30px) !important;
    }
  }

  &:nth-child(3) {
    .active & {
      transform: translateY(-4px) rotate(-45deg) scale(.7);
    }
  }

}
</style>
