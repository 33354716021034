<template>
  <spotlight ref="spotlightRef" v-slot="{ active, activate, close }" :active-width="isDesktop ? 0.3 : 0.9">
    <card class="dog-card" :image="dog.bild" :class="[ dog.gender, active && 'active' ]">
      <template #top>
        <div class="gender-icon">
          <female v-if="isFemale" />
          <male v-else />
        </div>
      </template>

      <cross class="close-btn" :class="{ show: active }" @click="close" />
      <div class="content">
        <h3 class="mb-4" v-html="dog.name" />
        <div v-if="!parent" class="texts">
          <div class="properties">
            <div class="row-2">
              <div class="col-1">
                <div class="text">
                  {{ dog.text.mother }}: {{ dog.mutter }}
                </div>
                <div class="text">
                  {{ dog.text.father }}: {{ dog.vater }}
                </div>
                <dropdown :expanded="!active" class="text not-full">
                  {{ dog.text.birth }}: {{ dog.geboren }}
                </dropdown>
              </div>
              <div class="col-1">
                <dropdown :expanded="active" class="text full">
                  {{ dog.text.birth }}: {{ dog.geboren }}
                </dropdown>
                <div class="text full">
                  {{ dog.text.weight }}: {{ dog.gewicht }}
                </div>
              </div>
            </div>
          </div>
          <dropdown :expanded="active" class="full text description pb-4">
            <div v-if="dog.beschreibung.trim().length" class="text description" v-html="dog.beschreibung" />
            <Go class="btn" v-if="dog.files && dog.files.length" :to="dog.files?.[0]">
              <download /> {{ dog.text.healthDocument }}
            </Go>
          </dropdown>
        </div>
        <div v-else class="parent">
          <div class="text parent-of">
            {{ isFemale ? dog.text.motherOf : dog.text.fatherOf }} {{ wurf }}
          </div>

          <div v-if="!active" class="text mb-12" v-html="dog.vorschau" />
          <div v-else v-html="dog.beschreibung" />
        </div>
        <button
          class="link btn more mt-auto"
          @click="activate"
        >
          {{ dog.text.more }}
        </button>
      </div>
    </card>
  </spotlight>
</template>

<script>
import { computed, ref } from 'vue';
import Male from '@/assets/images/male.svg?inline';
import Female from '@/assets/images/female.svg?inline';
import Cross from '@/assets/images/cross.svg?inline';
import Download from '@/assets/images/download.svg?inline';
import { useWithinBreakpoint } from '@/composables/breakpoints';

export default {
  components: {
    Male, Female, Cross, Download,
  },
  props: {
    dog: { type: Object, default: () => ({}) },
    parent: { type: Boolean, default: false },
    wurf: { type: String },
  },
  setup(props) {
    const isDesktop = useWithinBreakpoint('md');
    const spotlightRef = ref(null);
    return {
      isDesktop,
      console,
      spotlightRef,
      doTrack: () => spotlightRef.value.doTrack(),
      isFemale: computed(() => props.dog.gender.includes('female')),
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_mixin.scss';

.female h3 {
  @apply text-pink;
}

.dog-card {
  position: relative;
  width: 100%;

  --card-width: 90vw;

  @screen md {
    --card-width: 30vw;
  }

  &.active {
    max-width: var(--card-width);
    @include responsive('width', 90vw, auto);
    max-height: 80vh;
    overflow-y: auto;
  }

  .text.full,
  .text.not-full {
    transition: opacity var(--speed-fast) ease;
  }
  .text.full {
    opacity: 0;
  }
  .text.not-full {
    opacity: 1;
  }

  .description {
    width: 100%;

    @screen sm {
      width: calc(var(--card-width) - #{px(121)});
    }
  }

  .properties {
    margin-bottom: 2rem;
  }

  &.active {
    .text.full {
      opacity: 1;
    }
    .text.not-full {
      opacity: 0;
    }

    :deep(img) {
      aspect-ratio: 4 / 2;
    }
  }
}

.full.text .btn {
  @apply bg-orange-dark;

  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: px(8);
  justify-content: space-between;
  width: fit-content;
  font-size: px(18);
  line-height: px(20);

  @include responsive('padding', px(4) px(10), px(8) px(20));
}
.full.text .description {
  @include responsive('margin-bottom', px(22), px(48));
}

.gender-icon {
  @apply rounded-full;
  @apply bg-pink;

  position: absolute;
  display: flex;
  z-index: 5;

  aspect-ratio: 1 / 1;
  width: 78px;
  @include responsive('right', px(18), px(31));
  bottom: 30%;
  transform: translateY(50%);

  svg {
    max-width: 50%;
    max-height: 50%;
    margin: auto;
  }

  .male & {
    @apply bg-blue-medium;
  }
}

.content {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  flex: 1;
  margin-top: -5%;

  @include responsive('--padding-x', px(24), px(26));
  @include responsive('--padding-y', px(24), px(18));

  padding: var(--padding-y) var(--padding-x);
}

.date {
  @apply text-base;
  @apply font-bold;
  display: block;
  margin-left: auto;
  width: fit-content;
  margin-bottom: px(18);
  line-height: px(20);
}

.text {
  @apply text-base;
  line-height: px(22);
}

.parent {
  width: 100%;

  .btn {
    @apply bg-dark-gray;
    font-size: px(18);
    line-height: px(20);
  }
}

.link.btn {
  @apply bg-primary;
  @apply text-dark-orange;

  position: absolute;
  right: 0;
  bottom: 0;
  cursor: pointer;

  padding-top: px(60);
  padding-left: px(40);
  padding-bottom: px(23);

  margin-top: px(-80);

  border-radius: 0;
  line-height: px(28);
  align-self: flex-end;
  clip-path: ellipse(100% 100% at 100% 100%);
  shape-outside: ellipse(100% 100% at 100% 100%);

  transition: all var(--speed-fast) ease;

  &:hover {
    @apply bg-orange-dark;

    padding-top: px(80);
    padding-left: px(60);
    margin-top: px(-20);
  }

  .dog-card.active & {
    transform-origin: bottom right;
    transform: scale(0);
  }
}

.parent-of {
  @apply text-m;
  @apply font-bold;

  line-height: px(28);
  margin-bottom: px(30);
}

.close-btn {
  @apply text-white;

  opacity: 0;
  transition: opacity var(--speed-fast) ease;
  position: absolute;
  top: px(25);
  right: px(30);
  width: px(22);
  height: px(22);
  cursor: pointer;

  &.show {
    opacity: 1;
  }
}
</style>
