const colors = require('tailwindcss/colors');

module.exports = {
  purge: {
    content: [
      './public/**/*.html',
      './src/**/*.vue',
    ],
    options: {
      safelist: [
        // bg colors
        'bg-primary',
        'bg-secondary',
        'bg-active',
        'bg-blue',
        'bg-blue-dark',
        'bg-blue-light',
        'bg-blue-medium',
        'bg-orange',
        'bg-orange-dark',
        'bg-dark',
        'bg-dark-gray',
        'bg-pink',
      ],
    },
  },

  theme: {

    colors: {
      primary: {
        DEFAULT: 'var(--color-primary)',
      },
      secondary: {
        DEFAULT: 'var(--color-secondary)',
      },
      active: {
        DEFAULT: 'var(--color-active)',
      },
      blue: {
        DEFAULT: 'var(--color-blue)',
        dark: 'var(--color-dark-blue)',
        medium: 'var(--color-medium-blue)',
        light: 'var(--color-light-blue)',
      },
      orange: {
        DEFAULT: 'var(--color-orange)',
        dark: 'var(--color-dark-orange)',
      },
      dark: {
        DEFAULT: 'var(--color-dark)',
        gray: 'var(--color-dark-gray)',
      },
      pink: {
        DEFAULT: 'var(--color-pink)',
      },
      white: colors.white,
    },

    extend: {
      textColor: {
        blue: 'var(--color-blue)',
        'dark-blue': 'var(--color-dark-blue)',
        'light-blue': 'var(--color-light-blue)',
        'medium-blue': 'var(--color-medium-blue)',
        'dark-gray': 'var(--color-dark-gray)',
        'dark-orange': 'var(--color-dark-orange)',
        dark: 'var(--color-dark)',
        pink: 'var(--color-pink)',
        orange: 'var(--color-orange)',
      },
      borderRadius: {
        medium: '1.25rem',
      },
    },

    fontSize: {
      xs: '0.75rem', // = 12px
      sm: '0.875rem', // = 14px
      base: '1rem', // = 16px
      m: '1.25rem', // = 20px
      l: '1.625rem', // = 26px
      xl: '2.25rem', // = 36px
      xxl: '3.75rem', // = 60px
    },

    container: {
      center: true,
    },

    screens: {
      sm: '640px',
      md: '920px',
      lg: '1200px',
      xl: '1820px',
    },

  },
  variants: {
    extend: {},
  },
  plugins: [],
};
