<template>

  <transition-group name="fade">
    <component
      :is="component.component"
      v-for="component of parts"
      :id="component.slug || component.data.slug"
      :key="component.id || component.data.id"
      :payload="component.data || component"
      class="fade-item"
    />
  </transition-group>

</template>

<script>
import { defineAsyncComponent } from 'vue';

const NotFound = defineAsyncComponent(() => import('@/components/structure/NotFound.vue'));
const Footer = defineAsyncComponent(() => import('@/components/structure/Footer.vue'));
const Size = defineAsyncComponent(() => import('@/components/parts/Size.vue'));
const Newsletter = defineAsyncComponent(() => import('@/components/parts/Newsletter.vue'));
const HeroHeader = defineAsyncComponent(() => import('@/components/parts/HeroHeader.vue'));
const TextBild = defineAsyncComponent(() => import('@/components/parts/TextBild.vue'));
const WurfSlider = defineAsyncComponent(() => import('@/components/parts/WurfSlider.vue'));
const BlogTeaser = defineAsyncComponent(() => import('@/components/parts/BlogTeaser.vue'));
const BilderCarousel = defineAsyncComponent(() => import('@/components/parts/BilderCarousel.vue'));
const Wurf = defineAsyncComponent(() => import('@/components/parts/Wurf.vue'));
const TextKreislink = defineAsyncComponent(() => import('@/components/parts/TextKreislink.vue'));
const QA = defineAsyncComponent(() => import('@/components/parts/QA.vue'));
const Partner = defineAsyncComponent(() => import('@/components/parts/Partner.vue'));
const InfoLinie = defineAsyncComponent(() => import('@/components/parts/InfoLinie.vue'));

export default {
  components: {
    Size,
    NotFound,
    Footer,
    HeroHeader,
    TextBild,
    BlogTeaser,
    Wurf,
    Newsletter,
    BilderCarousel,
    WurfSlider,
    TextKreislink,
    QA,
    Partner,
    InfoLinie,
  },
  props: { inject: { type: Object, default: Object } },
  computed: {
    parts() {
      return this.inject.filter((part) => !part.data?.hidden);
    },
  },
};
</script>
