<template>
  <swiper
    :slides-per-view="responsiveSlidesPerView"
    :slides-per-group="responsiveSlidesPerGroup"
    :space-between="responsiveSpaceBetween"
    class="swiper slider"
    @swiper="onSwiper"
  >
    <swiper-slide
      v-for="(item, index) in items"
      :key="item.id"
      class="swiper-slide"
    >
      <slot :item="item" :index="index" />
    </swiper-slide>
  </swiper>
</template>

<script>
import { ref } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { useResponsive } from '@/composables/breakpoints';

const defaultSlides = {
  mobile: 1.1,
  desktop: 3,
};

const defaultSlidesPerGroup = {
  mobile: 1,
  desktop: 3,
};

const defaultSpace = {
  mobile: 25,
  desktop: 92,
};

export default {
  components: { Swiper, SwiperSlide },
  props: {
    items: { type: Array, default: () => [] },
    slidesPerView: { type: Object, default: () => defaultSlides },
    slidesPerGroup: { type: Object, default: () => defaultSlidesPerGroup },
    spaceBetween: { type: Object, default: () => defaultSpace },
  },
  setup(props) {
    const swiperRef = ref(null);

    const slidesPerView = useResponsive({ ...defaultSlides, ...props.slidesPerView });
    const slidesPerGroup = useResponsive({ ...defaultSlidesPerGroup, ...props.slidesPerGroup });
    const spaceBetween = useResponsive({ ...defaultSpace, ...props.spaceBetween });

    const onSwiper = (swiper) => {
      swiperRef.value = swiper;
    };

    const prev = () => swiperRef.value?.slidePrev();
    const next = () => swiperRef.value?.slideNext();

    return {
      onSwiper,
      responsiveSlidesPerView: slidesPerView,
      responsiveSlidesPerGroup: slidesPerGroup,
      responsiveSpaceBetween: spaceBetween,
      prev,
      next,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_mixin.scss";

.swiper-slide {
  height: auto;

  & > :deep(*) {
    height: 100%;
  }
}
</style>
