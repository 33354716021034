/* eslint-disable import/prefer-default-export */
export function cssVars(vals) {
  const cssCase = (name) => name.replace(/([A-Z])/g, (_, m) => `-${m.toLowerCase()}`);
  return Object.entries(vals).reduce((vars, [name, val]) => (val
    ? ({
      ...vars,
      [`--${cssCase(name)}`]: val,
    })
    : vars), {});
}
